import { __awaiter } from "tslib";
import { addToCartThrottler, cleanVariationCode } from './cart-throttler';
import { resolveData, getLocalStorageItem, setLocalStorageItem, URLX, currentUrl, postJson, pathCombine, } from '@avensia/scope';
import currentPageIsProduct from 'Product/current-page-is-product';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { fetchProducts, cleanBackendProducts, fetchTransaction, getCartTrackingProducts, fetchProduct, fetchTrackingVariant, } from './Api';
import { getCart, getCurrentUser } from 'Shared/dynamic-data';
import currentPageIsOrderConfirmation from 'OrderConfirmation/current-page-is-order-confirmation';
import { createGuid } from 'Shared/create-guid';
import { createAddToCartGa4Tracking, createAddToWishlistGa4Tracking, createBeginCheckoutGa4Tracking, createMarketSpecificPurchaseGa4Tracking, createPurchaseGa4Tracking, createRemoveFromCartGa4Tracking, createRemoveToWishlistGa4Tracking, createViewGa4Product, getLatestCart, setGa4Store, } from './ga4';
import { getCookie } from 'Shared/Helper/cookieHelper';
import { setUserID } from 'google-optimize';
const localStorageOrdersKey = 'gtmOrdersTracked';
let gtmEnabled = false;
let pageLoadPromise;
let store;
const facebookEventUrl = '/FacebookEvent';
export function setPageLoadPromise(promise) {
    pageLoadPromise = promise;
}
export function addToDataLayer(action, trackAfterPageLoad = true) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (gtmEnabled) {
            // Clear the previous e-commerce object. To have correct and updated data to data layer
            (_a = window === null || window === void 0 ? void 0 : window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({ ecommerce: null });
            if (trackAfterPageLoad) {
                yield pageLoadPromise;
            }
            setTimeout(() => { var _a; return (_a = window === null || window === void 0 ? void 0 : window.dataLayer) === null || _a === void 0 ? void 0 : _a.push(action); });
        }
    });
}
export function initTagManager(s) {
    var _a;
    store = s;
    setGa4Store(store);
    if (!('dataLayer' in window)) {
        window.dataLayer = [];
    }
    (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
        event: 'originalLocation',
        originalLocation: currentUrl().toString(),
    });
    gtmEnabled = true;
}
export function addToCart(products, listName, code, addedQuantity, affiliation, additional) {
    if (products.length === 0) {
        return;
    }
    const cartPayload = {
        products,
        listName,
        isAdd: true,
        code,
        addedQuantity,
    };
    const currentPageUrlClosure = currentUrl();
    const onAddedToCart = (data) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const facebookEventId = createGuid();
        if (gtmEnabled) {
            // send add to cart event to GA4
            const addedItems = yield fetchTrackingVariant(code, addedQuantity);
            const cartItems = yield getLatestCart();
            const total = getItemTotal(addedItems);
            const currency = store.getState().appShellData.currency;
            const salesChannel = yield getSalesChannel();
            // Add custom mtm suffix for tracking
            addedItems.forEach(item => {
                var _a;
                if (((_a = additional === null || additional === void 0 ? void 0 : additional.mtmMeasurements) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    item.id = cleanVariationCode(item.id) + `_${additional === null || additional === void 0 ? void 0 : additional.unit}`;
                }
            });
            if (addedItems) {
                addToDataLayer(createAddToCartGa4Tracking(cartItems, addedItems, facebookEventId, total, currency, salesChannel, affiliation));
            }
        }
        if ((_a = store.getState().appShellData.activeFacebookEvents) === null || _a === void 0 ? void 0 : _a.addToCart) {
            trackFacebookConversion('add_to_cart', {
                addedProducts: data.products.map(p => ({
                    code: p.id,
                    name: p.name,
                    isPackage: false,
                    price: parseFloat(p.price),
                    quantity: p.quantity,
                    sourceUrl: currentPageUrlClosure.toString(),
                })),
                sourceUrl: currentUrl().toString(),
                eventId: facebookEventId,
            });
        }
    });
    addToCartThrottler(cartPayload, { onAdded: onAddedToCart });
}
export function removeFromCart(products, listName, product) {
    if (!gtmEnabled || products.length === 0) {
        return;
    }
    const cartPayload = {
        products,
        listName,
        isAdd: false,
    };
    const onRemovedFromCart = (data) => __awaiter(this, void 0, void 0, function* () {
        const cartItems = yield getLatestCart();
        const total = getItemTotal(data.products);
        const currency = store.getState().appShellData.currency;
        if (product.quantity > 1) {
            data.products[0].quantity = product.quantity;
        }
        const salesChannel = yield getSalesChannel();
        addToDataLayer(createRemoveFromCartGa4Tracking(cartItems, data.products, total, currency, salesChannel));
    });
    addToCartThrottler(cartPayload, { onRemoved: onRemovedFromCart });
}
export function pageLoad(page, isDynamicPageLoad, state) {
    pageLoadPromise = new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
        yield pageLoadAsync(page, isDynamicPageLoad, state);
        resolve();
    }));
}
export function getItemTotal(products) {
    let sum = 0;
    products.forEach(a => (sum = sum + (a.quantity > 0 ? parseFloat(a.price) * a.quantity : parseFloat(a.price))));
    return sum.toFixed(2);
}
/**
 * This function will be called on all page loads, regardless if they're scope page
 * navigations or hard browser refreshes. Add things to the datalayer that should be tracked
 * in the page load. Note that you can push more than one action to the datalayer.
 * @param page The current page that has been loaded
 * @param isDynamicPageLoad Determines whether it has been a dynamic scope page load or hard browser page load
 * @param state The Redux state, if there is something needed from Redux to track
 */
function pageLoadAsync(page, isDynamicPageLoad, state) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const facebookPagePageviewEventId = createGuid();
        if ((_a = state.appShellData.activeFacebookEvents) === null || _a === void 0 ? void 0 : _a.pageView) {
            trackFacebookConversion('PageView', { sourceUrl: page.url, eventId: facebookPagePageviewEventId });
        }
        const facebookInitiateCheckoutEventId = createGuid();
        if (((_b = state.appShellData.activeFacebookEvents) === null || _b === void 0 ? void 0 : _b.initiateCheckout) && currentPageIsCheckout(page)) {
            trackFacebookConversion('InitiateCheckout', { sourceUrl: page.url, eventId: facebookInitiateCheckoutEventId });
        }
        if (!gtmEnabled) {
            return;
        }
        if (!page.trackingPageType) {
            return;
        }
        const userID = (_c = getCookie('UserID')) !== null && _c !== void 0 ? _c : '';
        setUserID(userID);
        let virtualPageLoadAction = {};
        if (isDynamicPageLoad) {
            virtualPageLoadAction = {
                title: (page.meta && page.meta.title) || '',
                url: page.url,
                pageType: page.trackingPageType,
                event: 'virtualPageLoad',
                type: 'dynamic',
                facebookEventId: facebookPagePageviewEventId,
            };
        }
        else {
            virtualPageLoadAction = {
                title: (page.meta && page.meta.title) || '',
                url: page.url,
                pageType: page.trackingPageType,
                event: 'virtualPageLoad',
                type: 'hard',
                market: store.getState().appShellData.currentCountry.alpha3,
                currency: store.getState().appShellData.currency,
                language: store.getState().appShellData.culture.toString(),
                facebookEventId: facebookPagePageviewEventId,
            };
        }
        addToDataLayer(virtualPageLoadAction);
        if (currentPageIsProduct(page)) {
            const products = cleanBackendProducts([Object.assign({}, page.trackingProduct)]);
            const total = getItemTotal(products);
            const currency = store.getState().appShellData.currency;
            const salesChannel = yield getSalesChannel();
            addToDataLayer(createViewGa4Product(products, page.facebookEventId, total, currency, salesChannel));
        }
        if (currentPageIsCheckout(page)) {
            const cart = yield resolveData(getCart());
            const items = cart.items;
            const salesChannel = yield getSalesChannel();
            const productsWithQuantityAndCorrectPrice = yield getCartTrackingProducts(items);
            if (productsWithQuantityAndCorrectPrice.length) {
                addToDataLayer(createBeginCheckoutGa4Tracking(productsWithQuantityAndCorrectPrice, salesChannel));
            }
        }
        if (currentPageIsOrderConfirmation(page)) {
            const orderNumber = page.orderDetails.orderNumber;
            let orderAlreadyTracked = Boolean(getLocalStorageItem(localStorageOrdersKey, []).find(s => s === orderNumber));
            if (!orderAlreadyTracked) {
                const id = new URLX(document.location.href).searchParams.get('id');
                fetchTransaction(id).then((transaction) => __awaiter(this, void 0, void 0, function* () {
                    // Need to check that we didn't send it here again, because it is async
                    const ordersTracked = getLocalStorageItem(localStorageOrdersKey, []);
                    orderAlreadyTracked = Boolean(ordersTracked.find(s => s === orderNumber));
                    if (orderAlreadyTracked) {
                        return;
                    }
                    const salesChannel = yield getSalesChannel();
                    addToDataLayer(createPurchaseGa4Tracking(transaction, page.facebookEventId, salesChannel));
                    addToDataLayer(createMarketSpecificPurchaseGa4Tracking(transaction, page.facebookEventId, salesChannel));
                    setLocalStorageItem(localStorageOrdersKey, [...ordersTracked, orderNumber]);
                }));
            }
        }
    });
}
/**
 * Names of product listings, for tracking purposes
 */
export const listNames = {
    checkout: 'checkout',
    recommendations: 'recommendations',
    relatedProducts: 'related-products',
    productPage: 'product-page',
    quickOrder: 'quick-order',
    unknown: 'unknown',
    orderHistory: 'order-history-detail',
    cart: 'cart',
    legReaderPage: 'leg-reader-page',
    listView: 'list-view',
    bulkOrder: 'bulk-order',
};
export function login() {
    // TODO Implement project specific login event
}
export function logout() {
    // TODO Implement project specific logout event
}
export function trackAddToWishList(code, variationCode) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const facebookEventId = createGuid();
        const cartItems = yield getLatestCart();
        let addedItems = [];
        const currency = store.getState().appShellData.currency;
        if ((_a = store.getState().appShellData.activeFacebookEvents) === null || _a === void 0 ? void 0 : _a.addToWishList) {
            trackFacebookConversion('AddToWishList', {
                productCode: code,
                sourceUrl: currentUrl().toString(),
                eventId: facebookEventId,
            });
        }
        if (!!variationCode) {
            addedItems = yield fetchProducts([variationCode]);
        }
        else {
            const resProducts = yield fetchProduct(code);
            const _addedProducts = resProducts === null || resProducts === void 0 ? void 0 : resProducts.shift();
            addedItems = [_addedProducts];
        }
        const total = getItemTotal(addedItems);
        const salesChannel = yield getSalesChannel();
        addToDataLayer(createAddToWishlistGa4Tracking(cartItems, addedItems, facebookEventId, total, currency, salesChannel));
    });
}
export function trackRemoveToWishList(code, variationCode) {
    return __awaiter(this, void 0, void 0, function* () {
        const facebookEventId = createGuid();
        const cartItems = yield getLatestCart();
        const cart = yield resolveData(getCart());
        const total = (cart === null || cart === void 0 ? void 0 : cart.viewData.total.exclVat) ? cart === null || cart === void 0 ? void 0 : cart.viewData.total.exclVat.toFixed(2) : '0.00';
        const currency = store.getState().appShellData.currency;
        let removedItems = [];
        if (!!variationCode) {
            removedItems = yield fetchProducts([variationCode]);
        }
        else {
            const resProducts = yield fetchProduct(code);
            const _addedProducts = resProducts === null || resProducts === void 0 ? void 0 : resProducts.shift();
            removedItems = [_addedProducts];
        }
        const salesChannel = yield getSalesChannel();
        addToDataLayer(createRemoveToWishlistGa4Tracking(cartItems, removedItems, facebookEventId, total, currency, salesChannel));
    });
}
export function trackFacebookConversion(endpoint, data) {
    return postJson(pathCombine(facebookEventUrl, endpoint), data);
}
export function getSalesChannel() {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield resolveData(getCurrentUser());
        return user != null && (user === null || user === void 0 ? void 0 : user.isB2B) ? SalesChannel.B2B : SalesChannel.B2C;
    });
}
export var SalesChannel;
(function (SalesChannel) {
    SalesChannel["B2B"] = "B2B";
    SalesChannel["B2C"] = "B2C";
})(SalesChannel || (SalesChannel = {}));
export var GA4Affiliation;
(function (GA4Affiliation) {
    GA4Affiliation["LEG_READER"] = "legReader";
    GA4Affiliation["CHANGED_QUANTITY"] = "changeQuantity";
    GA4Affiliation["QUICK_ORDER"] = "quickOrder";
    GA4Affiliation["ORDER_HISTORY"] = "orderHistory";
    GA4Affiliation["ORDER_HISTORY_REORDER_ITEM"] = "orderHistoryReorderItem";
    GA4Affiliation["ORDER_HISTORY_REORDER_ORDER"] = "orderHistoryReorderOrder";
    GA4Affiliation["PRODUCT_DETAIL_PAGE"] = "productDetailPage";
    GA4Affiliation["LIST_VIEW"] = "listView";
    GA4Affiliation["BULK_ORDER"] = "bulkOrder";
})(GA4Affiliation || (GA4Affiliation = {}));
export var Hubspot;
(function (Hubspot) {
    Hubspot["PURCHASE_FORM"] = "PurchaseForm";
    Hubspot["HS_FORM_FIELD_INPUT"] = "hs-form__field__input";
})(Hubspot || (Hubspot = {}));
export var GA4FindRetailer;
(function (GA4FindRetailer) {
    GA4FindRetailer["HEADER"] = "header";
    GA4FindRetailer["PRODUCT_DETAIL_PAGE"] = "productDetailPage";
})(GA4FindRetailer || (GA4FindRetailer = {}));
export var FindRetailerId;
(function (FindRetailerId) {
    FindRetailerId["GEO_LOCATION_BUTTON"] = "geolocationButton";
    FindRetailerId["DEALER_LOCATION_RESULT"] = "dealerLocationResult";
    FindRetailerId["CURRENT_LOCATION_BUTTON"] = "currentLocationButton";
    FindRetailerId["DEALER_SUGGESTION"] = "dealerSuggestion";
    FindRetailerId["DEALER_SEARCH_INPUT"] = "dealerSearchInput";
    FindRetailerId["DEALER_SEARCH_BUTTON"] = "dealerSearchButton";
})(FindRetailerId || (FindRetailerId = {}));
