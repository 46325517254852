import { __awaiter } from "tslib";
import { getJson, resolveData, useDynamicData } from '@avensia/scope';
import { useCheckoutState } from 'Checkout/Context';
import { useEffect } from 'react';
import { getCart, getCurrentUser } from 'Shared/dynamic-data';
import usePrevious from 'Shared/use-previous';
import { useMiniCart } from 'SiteLayout/MiniCart/use-minicart';
import { addToDataLayer, SalesChannel } from 'TrackingInformation';
import { getCartTrackingProducts, getWishlistTrackingProducts } from './Api';
let store;
export function setGa4Store(s) {
    store = s;
}
export function convertProductToGA4(product, index) {
    var _a, _b;
    if (product) {
        const { id, brand, price, categories, variant, name, quantity } = product;
        const currency = (_a = store.getState().appShellData) === null || _a === void 0 ? void 0 : _a.currency;
        const data = {
            item_id: id,
            item_brand: brand !== null && brand !== void 0 ? brand : '',
            item_name: name,
            item_variant: variant,
            price,
            currency,
            coupon: (_b = product.couponCodes) !== null && _b !== void 0 ? _b : '',
        };
        if (product.addedQuantity > 1) {
            data.quantity = product.addedQuantity;
        }
        else {
            data.quantity = quantity > 0 ? quantity : 1;
        }
        if (index) {
            data.index = index;
        }
        categories === null || categories === void 0 ? void 0 : categories.forEach((c, i) => {
            const suffix = i > 0 ? i + 1 : '';
            data['item_category' + suffix] = c;
        });
        return data;
    }
    return null;
}
export function createRemoveFromCartGa4Tracking(products, removedItems, total, currency, salesChannel) {
    return {
        event: 'remove_from_cart',
        ecommerce: {
            value: total,
            currency: currency,
            cartItems: products.map(convertProductToGA4),
            removedItems: removedItems.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
export function createAddToCartGa4Tracking(products, addedItems, facebookEventId, total, currency, salesChannel, affiliation) {
    const event = {
        event: 'add_to_cart',
        affiliation: affiliation,
        ecommerce: {
            value: total,
            currency: currency,
            cartItems: products.map(convertProductToGA4),
            addedItems: addedItems.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (facebookEventId) {
        event.facebookEventId = facebookEventId;
    }
    return event;
}
export function createViewGa4Product(products, facebookEventId, total, currency, salesChannel) {
    const event = {
        event: 'view_item',
        ecommerce: {
            value: total,
            currency: currency,
            viewItems: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (facebookEventId) {
        event.facebookEventId = facebookEventId;
    }
    return event;
}
export function createViewCartGa4(products, total, currency, salesChannel) {
    return {
        event: 'view_cart',
        ecommerce: {
            value: total,
            currency: currency,
            cartItems: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
export function createViewWishlistGa4(products, salesChannel) {
    return {
        event: 'view_wishlist',
        ecommerce: {
            items: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
export function createSignUpGa4() {
    return {
        event: 'sign_up',
    };
}
export function createPasswordRequestGa4() {
    return {
        event: 'password_request',
    };
}
export function createPasswordChangedGa4() {
    return {
        event: 'password_changed',
    };
}
export function createLoginGa4() {
    return {
        event: 'login',
    };
}
export function createLogoutGa4(market) {
    return {
        event: 'logout',
        culture: market,
    };
}
export function createQuickOrderSearchGa4(phrase) {
    return {
        event: 'quickordersearch',
        searchphrase: phrase,
    };
}
export function createFindRetailerClickGa4(source) {
    return {
        event: 'find_retailer_click',
        source,
    };
}
export function createBeginCheckoutGa4Tracking(products, salesChannel) {
    return {
        event: 'begin_checkout',
        ecommerce: {
            cartItems: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
export function createProductCustomSavedGa4() {
    return {
        event: 'product_custom_saved',
    };
}
export function createAddToWishlistGa4Tracking(products, addedItems, facebookEventId, total, currency, salesChannel) {
    const event = {
        event: 'add_item_wishlist',
        ecommerce: {
            value: total,
            currency: currency,
            cartItems: products.map(convertProductToGA4),
            addedItems: addedItems.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (facebookEventId) {
        event.facebookEventId = facebookEventId;
    }
    return event;
}
export function createRemoveToWishlistGa4Tracking(products, removedItems, facebookEventId, total, currency, salesChannel) {
    const event = {
        event: 'remove_item_wishlist',
        ecommerce: {
            value: total,
            currency: currency,
            cartItems: products.map(convertProductToGA4),
            removedItems: removedItems.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (facebookEventId) {
        event.facebookEventId = facebookEventId;
    }
    return event;
}
export function createMarketSpecificPurchaseGa4Tracking(transaction, facebookEventId, salesChannel) {
    const purchaseEvent = {
        event: salesChannel == SalesChannel.B2B ? 'purchase_b2b' : 'purchase_b2c',
        ecommerce: {
            currency: transaction.currencyCode,
            value: transaction.revenue,
            tax: transaction.tax,
            shipping: transaction.shipping,
            transaction_id: transaction.transactionId,
            cartItems: transaction.products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (transaction.coupon) {
        purchaseEvent.ecommerce.coupon = transaction.coupon;
    }
    if (facebookEventId) {
        purchaseEvent.facebookEventId = facebookEventId;
    }
    return purchaseEvent;
}
export function createPurchaseGa4Tracking(transaction, facebookEventId, salesChannel) {
    const purchaseEvent = {
        event: 'purchase',
        ecommerce: {
            currency: transaction.currencyCode,
            value: transaction.revenue,
            tax: transaction.tax,
            shipping: transaction.shipping,
            transaction_id: transaction.transactionId,
            cartItems: transaction.products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
    if (transaction.coupon) {
        purchaseEvent.ecommerce.coupon = transaction.coupon;
    }
    if (facebookEventId) {
        purchaseEvent.facebookEventId = facebookEventId;
    }
    return purchaseEvent;
}
function createAddShippingInfoGa4Tracking(shippingTier, products, salesChannel) {
    return {
        event: 'add_shipping_info',
        ecommerce: {
            shipping_tier: shippingTier,
            cartItems: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
function createAddPaymentInfoGa4Tracking(paymentType, products, salesChannel) {
    return {
        event: 'add_payment_info',
        ecommerce: {
            payment_type: paymentType,
            cartItems: products.map(convertProductToGA4),
            salesChannel: salesChannel,
        },
    };
}
function getSalesChannel() {
    const user = useDynamicData(getCurrentUser());
    return user != null && (user === null || user === void 0 ? void 0 : user.isB2B) ? SalesChannel.B2B : SalesChannel.B2C;
}
export function usePaymentInfoTracking() {
    const { checkoutData } = useCheckoutState();
    const { paymentMethods, paymentMethodId, items } = checkoutData;
    const previousPaymentId = usePrevious(paymentMethodId);
    const selectedPaymentMethod = paymentMethods.find(s => s.id === paymentMethodId);
    const salesChannel = getSalesChannel();
    useEffect(() => {
        if (selectedPaymentMethod && paymentMethodId !== previousPaymentId) {
            getCartTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                addToDataLayer(createAddPaymentInfoGa4Tracking(selectedPaymentMethod.name, productsWithQuantityAndCorrectPrice, salesChannel));
            });
        }
    }, [paymentMethodId, previousPaymentId, selectedPaymentMethod, items]);
}
export function useShippingInfoTracking() {
    const { checkoutData } = useCheckoutState();
    const { shippingMethods, shippingMethodId, items } = checkoutData;
    const previousShippingMethodId = usePrevious(shippingMethodId);
    const salesChannel = getSalesChannel();
    useEffect(() => {
        const selectedShippingMethod = shippingMethods.find(s => s.id === shippingMethodId);
        if (selectedShippingMethod && shippingMethodId !== previousShippingMethodId) {
            getCartTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                addToDataLayer(createAddShippingInfoGa4Tracking(selectedShippingMethod.name, productsWithQuantityAndCorrectPrice, salesChannel));
            });
        }
    }, [shippingMethodId, previousShippingMethodId, shippingMethods, items]);
}
export function useViewMiniCartGa4Gracking(hasBeenOpen) {
    const { isOpen } = useMiniCart();
    const cart = useDynamicData(getCart());
    const items = cart === null || cart === void 0 ? void 0 : cart.items;
    const total = (cart === null || cart === void 0 ? void 0 : cart.viewData.total.exclVat) ? cart === null || cart === void 0 ? void 0 : cart.viewData.total.exclVat.toFixed(2) : '0.00';
    const currency = store.getState().appShellData.currency;
    const salesChannel = getSalesChannel();
    useEffect(() => {
        if (isOpen && items && !hasBeenOpen) {
            getCartTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                addToDataLayer(createViewCartGa4(productsWithQuantityAndCorrectPrice, total, currency, salesChannel));
            });
        }
    }, [hasBeenOpen, isOpen, items, total, currency]);
}
export function viewWishlistGa4Gracking() {
    const getItems = () => getJson('/wishlist/getProducts');
    const salesChannel = getSalesChannel();
    getItems().then(items => {
        if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
            getWishlistTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                addToDataLayer(createViewWishlistGa4(productsWithQuantityAndCorrectPrice, salesChannel));
            });
        }
    });
}
export function getLatestCart() {
    return __awaiter(this, void 0, void 0, function* () {
        const cart = yield resolveData(getCart());
        const items = cart === null || cart === void 0 ? void 0 : cart.items;
        if (items) {
            return getCartTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                return productsWithQuantityAndCorrectPrice;
            });
        }
    });
}
export function useBeginCheckout() {
    const cart = useDynamicData(getCart());
    const items = cart === null || cart === void 0 ? void 0 : cart.items;
    const salesChannel = getSalesChannel();
    useEffect(() => {
        if (items) {
            getCartTrackingProducts(items).then(productsWithQuantityAndCorrectPrice => {
                addToDataLayer(createBeginCheckoutGa4Tracking(productsWithQuantityAndCorrectPrice, salesChannel));
            });
        }
    }, [items]);
}
